/* eslint-disable max-len */
export const landlinePhoneRegex = /^((\(?0\d{4}\)?\s?\d{3}\s?\d{3})|(\(?0\d{3}\)?\s?\d{3}\s?\d{4})|(\(?0\d{2}\)?\s?\d{4}\s?\d{4}))(\s?\#(\d{4}|\d{3}))?$/i;
export const mobilePhoneRegex = /^(\+44\s?7\d{3}|\(?07\d{3}\)?)\s?\d{3}\s?\d{3}$/i;
const dummyNumbers = [
    "00000000000", "11111111111", "22222222222", "33333333333", "44444444444", "55555555555", "66666666666", "77777777777",
    "88888888888", "99999999999", "07000000000", "07111111111", "07222222222", "07333333333", "07444444444", "07555555555",
    "07666666666", "07777777777", "07888888888", "07999999999",
];
export default function testPhone(value) {
    return (landlinePhoneRegex.test(value) || mobilePhoneRegex.test(value)) && !dummyNumbers.includes(value);
}
