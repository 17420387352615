import { number, object, string, } from "yup";
import testPostcode from "@validation/testPostcode";
import testPhone from "@validation/testPhone";
import addressSchema from "@validation/addressSchema";
const formSchemaDefinition = {
    postcode: string().required("This field is required").test("test-postcode", "Please enter a valid UK postcode", testPostcode),
    lookupAddress: addressSchema.nullable().default(null),
    numberOfBedrooms: string().required("This field is required"),
    estimatedValue: string().required("This field is required"),
    periodToList: string().required("This field is required"),
    fullName: string().required("This field is required"),
    emailAddress: string().email("Please enter a valid email address").required("This field is required"),
    phone: string().required("This field is required").test("test-phone", "Please enter a valid UK Phone number", testPhone),
    address: addressSchema.nullable().required("This field is required").default(null),
    agentID: number().required(), // todo: agentID needs to be set to proceed past the selector step
};
export const formSchema = object(formSchemaDefinition);
